<template>
  <div>
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div v-if="!loading" class="up-course">
      <h1 class="up-main-title">{{ courseInfo.course.name }}</h1>
      <div v-html="courseInfo.course.description" class="up-course__text" />
      <el-tag v-if="isCompleted" type="success">
        Курс {{ statusTranslated }}
      </el-tag>
      <div v-else class="up-course__info">
        <p class="up-course__info-text">
          Пройдено уроков: {{ countCompletedLessons }} из
          {{ courseInfo.steps.length }}
        </p>
        <el-button type="primary" @click="startLesson">
          <template v-if="countCompletedLessons">Продолжить</template>
          <template v-else>Начать</template>
          обучение
        </el-button>
      </div>
      <AppBlockAsTable class="up-course__table">
        <div v-for="(l, i) in courseInfo.steps" :key="i" class="up-table__item">
          <p class="up-course__table-type">
            {{ i + 1 }}. {{ lessonTypeTranslated(l.lessonType) }}
          </p>
          <p class="up-course__table-name">
            <router-link
              v-if="l.state === 'done' && !isFailed"
              :to="`${$route.path}/lesson/${l.id}`"
            >
              {{ l.name }}
            </router-link>
            <template v-else> {{ l.name }}</template>
          </p>
          <p v-if="l.state === 'done'" class="up-course__table-icon">
            <i class="el-icon-circle-check" />
          </p>
        </div>
      </AppBlockAsTable>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppBreadcrumbs from "@/components/AppBreadcrumbs";

import courseStatuses from "@/enums/courseStatuses";

export default {
  name: "CourseShow",
  components: { AppBlockAsTable, AppBreadcrumbs },
  data: () => ({
    courseInfo: {},
    loading: true,
    breadcrumbs: [
      {
        text: "Все курсы",
        path: "/listener",
      },
      {
        text: "Текущий курс",
      },
    ],
  }),
  computed: {
    isCompleted() {
      return (
        this.courseInfo.flowState === "closed" ||
        this.courseInfo.flowState === "timeout" ||
        this.courseInfo.flowState === "done"
      );
    },
    isFailed() {
      return (
        this.courseInfo.flowState === "closed" ||
        this.courseInfo.flowState === "timeout"
      );
    },
    countCompletedLessons() {
      return this.courseInfo.steps.reduce((acc, l) => {
        return l.state === "done" ? acc + 1 : acc;
      }, 0);
    },
    statusTranslated() {
      const status = courseStatuses.find(
        (s) => s.state === this.courseInfo.flowState
      );

      return status ? status.text : "";
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    lessonTypeTranslated(type) {
      return type === "lecture" ? "Лекция" : "Тест";
    },
    async fetchCourseInfo() {
      try {
        const { data: courseInfo } = await this.$axios.get(
          `/listener/flow/${this.$route.params.id}`
        );

        this.courseInfo = courseInfo;

        localStorage.setItem(
          "currentLessons",
          JSON.stringify(this.courseInfo.steps)
        );
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    startLesson() {
      const currentLesson =
        this.courseInfo.activeStep || this.courseInfo.steps[0].id;

      this.$router.push(`${this.$route.path}/lesson/${currentLesson}`);
    },
  },
  async created() {
    await this.fetchCourseInfo();

    this.setNavigationText(`Курс: ${this.courseInfo.course.name}`);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/listener/course/index.scss";
</style>